<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Subjects of {{ lists.year.value }} Academic Year</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link v-can="'access_academic_years'" :to="{ name: 'Academic-Years' }">Academic Years</router-link>
          </li>
          <li class="breadcrumb-item active">Subjects</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link v-if="lists.year !== null" v-can="'add_subjects'" :to="{
              name: 'Academic-Years-Subjects-Create',
              params: { year_id: props.year_id },
            }" class="btn btn-primary">Add New</router-link>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <form @submit.prevent="submitSearchForm()" class="form-inline">
                  <!-- Subject Name -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Name</label>
                    <input type="text" class="form-control" v-model="search_fields.name" name="free_search_input"
                      placeholder="Subject Name">
                  </div>

                  <!-- term -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Term</label>
                    <select class="form-control" v-model="search_fields.term_id">
                      <option value="" selected>All</option>
                      <option v-for="term in lists.terms" :key="term.id" :value="term.id">{{ term.name }}
                      </option>
                    </select>
                  </div>

                  <!-- term -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Level</label>
                    <select class="form-control" v-model="search_fields.level_id">
                      <option value="" selected>All</option>
                      <option v-for="level in lists.levels" :key="level.id" :value="level.id">{{ level.value }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-2 mr-2">
                    <button name="search" type="submit" class="btn btn-primary" value="Filter" onclick="this.blur();">
                      Filter
                    </button>
                  </div>

                </form>
              </div>
            </div>
            <div class="table-responsive">
              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="subjects" alternating buttons-pagination>
                <template #item-actions="{ id }">
                  <div>
                    <router-link v-can="'view_subjects'" :to="{
                      name: 'Academic-Years-Subjects-Single',
                      params: { id: id },
                    }" title="View" class="mr-4"><i class="fa fa-eye color-muted"></i>
                    </router-link>
                    <router-link v-can="'edit_subjects'" :to="{
                      name: 'Academic-Years-Subjects-Edit',
                      params: { id: id },
                    }" title="Edit" class="mr-4"><i class="fa fa-pencil color-muted"></i>
                    </router-link>
                    <a v-can="'delete_subjects'" @click.prevent="deleteData(id)" href="javascript:void()"
                      title="Delete"><i class="fa fa-close color-danger"></i></a>
                  </div>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useSubject from "@/composables/subjects";
import { inject, onBeforeMount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
// import Multiselect from "vue-multiselect";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
export default {
  components: {
    EasyDataTable,
  },
  props: {
    year_id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const loading = ref(true);

    const search_fields = reactive({
      name: "",
      term_id: '',
      level_id: '',
    });

    const { getSubjects, deleteSubject, subjects, lists } = useSubject();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("access_subjects")) {
        router.push({
          name: "401",
        });
      } else {
        await getSubjects(props.year_id).then(() => {
          loading.value = false;
        });
      }
    });

    const headers = ref([
      { text: "ID", value: "id" },
      { text: "Name", value: "name", sortable: true },
      { text: "Academic Year", value: "year.value", sortable: true },
      { text: "Term", value: "term.name", sortable: true },
      { text: "Level", value: "level.value", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    const deleteData = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteSubject(id).then(() => {
              swal.fire(
                "Deleted!",
                "The data has been deleted successfully.",
                "success"
              );
              getSubjects();
            });
          }
        });
    };

    const submitSearchForm = async () => {
      loading.value = true;
      await getSubjects(props.year_id, search_fields).then(() => {
        loading.value = false;
      });
    };

    return {
      loading,
      headers,
      props,
      lists,
      subjects,
      search_fields,
      deleteData,
      submitSearchForm
    };
  },
};
</script>
 
<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
